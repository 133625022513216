<template>
  <div>

    <div class="row">
      <div class="col">
        

     
      </div>
    </div>

    <div class="row">
      <div class="col-12">
           <h4 class="text-muted text-center">
          Selecione o prazo de renovação
        </h4>
      </div>

      <div @click="setPrazo(prazo)" v-for="prazo of prazosDisponiveis" :key="prazosDisponiveis.indexOf(prazo)" class="col-12 border p-3 rounded d-flex justify-content-between mb-3 default-hover">
        <h4>
          {{prazo.nome}}
        </h4>

        <div>
          <b>Custo de renovação: </b>
        {{ prazo.custo | currencyWithMask }}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  components: {
    // formaPagamentoForm: () =>
    //   import("../../Caixas/CaixaFormaPagamentoForm.vue"),
  },
  props: {
    fidelizacao: Object,
    tiposFildel:Array
  },
  data() {
    return {
      dadosRenovacao: {
        fidelizacao: this.fidelizacao,
        formasDePagamento: [],
        valorRenovacao: this.fidelizacao?.valor_renovacao,
        valorPago: 0,
      },
     prazosDisponiveis: [
       {
         prazo: 4,
         nome: "4 Meses",
         custo: 70
       },
       {
         prazo: 12,
         nome: "12 Meses",
         custo: 70
       },
     ],
    };
  },
  mounted(){
    console.log('atu', this.fidelizacao);
    if((!this.dadosRenovacao.valorRenovacao || this.dadosRenovacao.valorRenovacao===0) && this.tiposFildel && this.tiposFildel.length>0){
      let find =  this.tiposFildel.filter(x=>x.id!=3&&x.prazo == 12)
      console.log('findremov',find);
    }
  },
  methods: {
   setPrazo(prazo){
     this.$emit('prazo', prazo)
   }
  },
  watch:{
    // 'dadosRenovacao.formasDePagamento':{
    //   deep: true,
    //   handler:this.handleFormaPagamento
    // }
  }
};
</script>

<style></style>
